import React, { useEffect, useRef, useState } from 'react';
import { Trip, User } from '../models';
import Multiselect from 'multiselect-react-dropdown';
import { getTripsByUsers } from '../api/trips-api';

type FindProps = {
  appView: String;
  users: User[];
  setShowSpinner: (show: boolean) => void;
};

function Find({ appView, users, setShowSpinner }: FindProps) {
  const multiselectRef = useRef<Multiselect>(null);
  const [selectedUsersCount, setselectedUsersCount] = useState(0);
  const [trips, setTrips] = useState<Trip[]>([]);
  const [tripsFetched, setTripsFetched] = useState(false);

  useEffect(() => {
    if (appView) {
      multiselectRef.current?.resetSelectedValues();
    }
  }, [appView]);

  function handleButtonClick() {
    if (multiselectRef.current) {
      const users = multiselectRef.current.getSelectedItems();
      setShowSpinner(true);
      getTripsByUsers(users)
        .then((response) => {
          const trips: Trip[] = response.data;
          setTrips(trips);
          setTripsFetched(true);
        })
        .finally(() => setShowSpinner(false));
    }
  }

  function handleSelect() {
    const count = multiselectRef.current?.getSelectedItemsCount();
    setselectedUsersCount(count);
    setTripsFetched(false);
    setTrips([]);
  }

  function handleRemove() {
    const count = multiselectRef.current?.getSelectedItemsCount();
    setselectedUsersCount(count);
    setTripsFetched(false);
    setTrips([]);
  }

  function getTripsByUserTable(): React.ReactNode {
    const selectedUsers: User[] = multiselectRef.current?.getSelectedItems();

    if ((selectedUsers && selectedUsers.length < 2) || !tripsFetched) {
      return undefined;
    } else if (trips.length === 0 && tripsFetched) {
      return <p style={{ color: 'red', fontWeight: 'bold' }}>No trips found</p>;
    }

    const tripCountsByUser = [
      ...selectedUsers.map((user) => {
        return {
          _id: user._id,
          count: 0,
        };
      }),
    ];

    trips.forEach((trip) => {
      const entry = tripCountsByUser.find((entry) => entry._id === trip.driver);
      if (entry) {
        entry.count += 1;
      }
    });

    return (
      <table className='table'>
        <thead className='thead'>
          <tr>
            <th scope='col'>Name</th>
            <th scope='col'>Count</th>
          </tr>
        </thead>
        <tbody>
          {tripCountsByUser
            .sort((a, b) => a.count - b.count)
            .map((tripCount) => {
              const user: User | undefined = users.find(
                (user: User) => user._id === tripCount._id
              );
              if (user) {
                return (
                  <tr key={user._id}>
                    <td>{user.name}</td>
                    <td>
                      {
                        tripCountsByUser.find((entry) => entry._id === user._id)
                          ?.count
                      }
                    </td>
                  </tr>
                );
              } else {
                return undefined;
              }
            })}
        </tbody>
      </table>
    );
  }

  return (
    <div>
      <label style={{ fontWeight: 'bold' }}>Persons</label>
      <div
        className='my-2'
        style={{
          height: '45px',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div style={{ width: '100%' }}>
          <Multiselect
            style={{
              searchBox: {
                height: '45px',
                overflowY: 'auto',
              },
            }}
            options={users}
            hidePlaceholder
            displayValue='name'
            onSelect={handleSelect}
            onRemove={handleRemove}
            ref={multiselectRef}
          />
        </div>
        <button
          id='button-find-trips'
          className='btn btn-success ms-2'
          style={{ width: '100px' }}
          onClick={handleButtonClick}
          disabled={selectedUsersCount < 2}
        >
          OK
        </button>
      </div>
      <div className='mt-3'>{getTripsByUserTable()}</div>
    </div>
  );
}

export default Find;
