import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { NewTrip, User } from '../models';
import { addTrip } from '../api/trips-api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type AddProps = {
  appView: String;
  users: User[];
  updateTrips: () => void;
  setShowSpinner: (show: boolean) => void;
};

function Add({ appView, users, updateTrips, setShowSpinner }: AddProps) {
  const singleSelectRef = useRef<Multiselect>(null);
  const multiSelectRef = useRef<Multiselect>(null);
  const [passengers, setPassengers] = useState<User[]>([]);
  const [driver, setDriver] = useState<User>();
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    if (appView) {
      clearAndResetAllSelects();
      document.getElementById('#alertPlaceholder')?.replaceChildren();
    }
  }, [appView]);

  function clearAndResetAllSelects() {
    setDriver(undefined);
    setPassengers([]);
    singleSelectRef.current?.resetSelectedValues();
    multiSelectRef.current?.resetSelectedValues();
  }

  function handleSingleSelect() {
    const selectedDriver: User = singleSelectRef.current?.getSelectedItems()[0];
    setDriver(selectedDriver);
    const passengerOptions: User[] = users.filter(
      (user) => user._id !== selectedDriver._id
    );
    if (passengerOptions && passengerOptions.length) {
      setPassengers(passengerOptions);
    }
  }

  function handleSingleRemove() {
    setDriver(undefined);
    setPassengers([]);
    multiSelectRef.current?.resetSelectedValues();
  }

  function handleOnClick() {
    const selectedPassengers = multiSelectRef.current?.getSelectedItems();
    const persons: User[] = [];
    if (driver && selectedPassengers.length) {
      persons.push(driver);
      persons.push(...selectedPassengers);

      const trip: NewTrip = {
        date: startDate.toISOString(),
        name: 'Work',
        driver: driver._id,
        persons: persons.map((person) => person._id).sort(),
      };

      setShowSpinner(true);
      addTrip(trip)
        .then((response) => {
          clearAndResetAllSelects();
          updateTrips();
          showAlertMessage('Trip saved successfully.', 'success');
        })
        .catch((err) => {
          showAlertMessage('Error occured trying to save the trip.', 'danger');
        })
        .finally(() => setShowSpinner(false));
    }
  }

  function showAlertMessage(message: any, type: String) {
    const alertPlaceholder = document.getElementById('alertPlaceholder');
    const wrapper = document.createElement('div');
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      `   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>`,
      `</div>`,
    ].join('');
    alertPlaceholder?.append(wrapper);
  }

  return (
    <div>
      <div id='alertPlaceholder'></div>
      <label style={{ fontWeight: 'bold' }}>Driver</label>
      <div style={{ width: '100%' }}>
        <Multiselect
          style={{
            searchBox: {
              height: '45px',
              overflowY: 'auto',
            },
          }}
          className='my-2'
          options={users}
          hidePlaceholder
          displayValue='name'
          selectionLimit={1}
          onSelect={handleSingleSelect}
          onRemove={handleSingleRemove}
          ref={singleSelectRef}
        />
      </div>

      <label style={{ fontWeight: 'bold' }}>Passengers</label>
      <div style={{ width: '100%' }}>
        <Multiselect
          className='my-2'
          style={{
            searchBox: {
              height: '45px',
              overflowY: 'auto',
            },
          }}
          options={passengers}
          hidePlaceholder
          displayValue='name'
          disable={!driver}
          ref={multiSelectRef}
        />
      </div>

      <label style={{ fontWeight: 'bold' }}>Date</label>
      <div>
        <DatePicker
          className='my-2'
          selected={startDate}
          calendarStartDay={1}
          onChange={(date: Date) => setStartDate(date)}
          dateFormat={'dd.MM.yyyy'}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>

      <button
        id='button-find-trips'
        className='btn btn-success mt-3'
        style={{ width: '100%' }}
        disabled={!driver || passengers.length < 1}
        onClick={handleOnClick}
      >
        OK
      </button>
    </div>
  );
}

export default Add;
