import axios from 'axios';
import { NewTrip, Trip, User } from '../models';

export function getAllTrips() {
  return axios.get('/api/trips');
}

export function getTripsByUsers(users: User[]) {
  const user_ids = users.map((user) => user._id).sort();
  return axios.get('/api/trips/byUsers', { params: { user_ids } });
}

export function addTrip(trip: NewTrip) {
  return axios.post('/api/trips', trip);
}

export function deleteTrip(trip: Trip) {
  return axios.delete(`/api/trips`, { data: trip });
}
