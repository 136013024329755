import { RotatingLines } from 'react-loader-spinner';
import { deleteTrip } from '../api/trips-api';
import { Trip, User } from '../models';

type LogProps = {
  users: User[];
  trips: Trip[];
  showSpinner: boolean;
  updateTrips: () => void;
  setShowSpinner: (show: boolean) => void;
};

function Log({
  users,
  trips,
  showSpinner,
  updateTrips,
  setShowSpinner,
}: LogProps) {
  function formatDate(date: Date) {
    const yyyy: number = date.getFullYear();
    const month: number = date.getMonth() + 1; // Months start at 0!
    const day: number = date.getDate();
    let mm: String = month.toString();
    let dd: String = day.toString();

    if (month < 10) mm = '0' + mm;
    if (day < 10) dd = '0' + dd;

    return dd + '.' + mm + '.' + yyyy;
  }

  function handleDeleteClick(e: any) {
    const trip_id = e.target.parentElement.parentElement.parentElement.id;
    const trip: Trip | undefined = trips.find((trip) => trip._id === trip_id);

    setShowSpinner(true);
    if (trip) {
      deleteTrip(trip)
        .then((response) => {
          updateTrips();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setShowSpinner(false));
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          zIndex: '10',
          position: 'absolute',
          minWidth: '75px',
          width: '10%',
          left: '50%',
          top: '50px',
          transform: 'translate(-50%)',
        }}
      >
        <RotatingLines
          strokeColor='grey'
          strokeWidth='5'
          animationDuration='0.75'
          width='100%'
          visible={showSpinner}
        />
      </div>
      <table
        id='log-table'
        className='table'
        data-pagination='true'
        data-page-size='5'
      >
        <thead className='thead'>
          <tr>
            <th scope='col'>Date</th>
            <th scope='col'>Driver</th>
            <th scope='col'>Persons</th>
            <th scope='col'></th>
          </tr>
        </thead>
        <tbody>
          {trips
            .sort(
              (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
            )
            .map((trip: Trip) => (
              <tr key={trip._id} id={trip._id}>
                <td>{`${formatDate(new Date(trip.date))}`}</td>
                <td>{`${users.find((user) => user._id === trip.driver)
                  ?.name}`}</td>
                <td>{`${trip.persons
                  .map(
                    (person) => users.find((user) => user._id === person)?.name
                  )
                  .join(', ')}`}</td>
                <td>
                  <button
                    className='btn btn-flat'
                    style={{ padding: '0px' }}
                    onClick={handleDeleteClick}
                  >
                    <i className='bi bi-trash'></i>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default Log;
